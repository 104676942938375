import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function ResponsiveDatePickers({setDate}) {

  const [selectedDate, setSelectedDate] = useState(new Date());
    

  function handleFromDateChange(value) {
    setSelectedDate(value)
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setDate(formattedDate)
    
  }

  return (

        <DatePicker
          selected={selectedDate}
          className="date-picker"
          onChange={handleFromDateChange}
          dateFormat="yyyy-MM-dd"
        />
      );
    };
    
    
  
