
import './App.css';
import React from 'react';
import Bookings from './component/Bookings/Index'

const App = () => {
  return (
     <>
       <Bookings />
     </>
  );
};

export default App;