import axios from 'axios';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React,{useState,useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function BasicSelect({setCity,setSelectedOption,setClinicValue,setFlages }) {

  const [age, setAge] = React.useState(1);
  const [mydata,setMydata]=useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://org.vetic.in/clinic-org/cities/`);
         setMydata(response?.data?.data)
      
      } catch (error) {
        console.error(error);
      }
    };

    fetchData()

  }, []);


  const handleChange = (event) => {
    setAge(event.target.value);
    setCity(event.target.value)
    setSelectedOption([])
    setClinicValue([])
    setFlages(false)
  };


  return (
    <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Age"
            onChange={handleChange}
          >
          {mydata?.map((value,id)=>{

            return   <MenuItem value={value.id} key={id}  >{value.name}</MenuItem>

          })}
          </Select>
        </FormControl>
    </Box>
  );
}
