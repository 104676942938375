import axios from 'axios';
import  React,{useState} from "react";
import Modal from "@mui/material/Modal";
import { Paper,Box } from "@mui/material";
import { styled } from "@mui/material/styles";



const DivView = styled("div")(({ theme }) => ({
  borderradius: 12,
  [theme.breakpoints.up("sm")]: {
    position: "relative",
    top: '30%',
    width: '100%',  
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    top: '20%',
    left:'0',
    right: "0",
    width: '100%',
  },
  
}));

const FormView = styled(Paper)(({ theme }) => ({
    borderradius: 8,
    [theme.breakpoints.down("sm")]: {
      padding: '14px 16px',
      width:'20rem',
    },
    [theme.breakpoints.up("sm")]: {
      padding: '1.8rem',
      width: '33%',
      borderRadius:"20px",
      position: "fixed",
  },
  [theme.breakpoints.up('md')]: {
    width: '35%',
    height:"10rem"
  },
  }));
  
export default function BasicModal({text, app_detail,vet_Instance_id}) {

  const [open, setOpen] = React.useState(false);
  const [appointmentDetails,setAppointmentDetails]=useState(false)
  const [error,setError]=useState(false)

  const handleClose = () => setOpen(false);
  const handleOpen = () =>{setOpen(true)}

  // to detect am and pm


  function convertTime(time24h) {
  
    const [hours, minutes] = time24h.split(':');
    let period = '';
  
    // Convert hours to 12-hour format
    let hours12h = parseInt(hours);
    if (hours12h === 0) {
      hours12h = 12;
      period = 'am';
    } else if (hours12h === 12) {
      period = 'pm';
    } else if (hours12h > 12) {
      hours12h -= 12;
      period = 'pm';
    } else {
      period = 'am';
    }
  
    const time12h = hours12h + ':' + minutes + ' ' + period;
    return time12h;
  }
  
  const time24h = '13:20';
  const time12h = convertTime(time24h);
  
    // to fetch bookings details

    const handelClinic=(id,vet_Instance_id)=>{

      const fetchData = async () => {
          try {
            const response = await axios.get(`https://booking.vetic.in/v2/bde/bookings/detail/${id}/${vet_Instance_id}/`);
            
            setAppointmentDetails(response?.data?.data)
          
          } catch (error) {
            console.error(error);
            setError(true)

          }
        };
       if(id)
       fetchData()
       if(app_detail===null){

       }else{
         setOpen(true)
       }
       
  
  }
  const Loader=()=>{
    return (<div className='loader-body'> 
            <div class="loader">
               <span></span>
               <span></span>
               <span></span>
              </div>
            </div>
            )}


  return (
    <>
    
          <button className={`${app_detail===null?'slot-button':"slot-button-active"}` } onClick={(e)=>handelClinic(app_detail,vet_Instance_id)} >
              <span  >
              {convertTime(text)}
              </span>
          </button>
        

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         <DivView>
           <FormView>
           {appointmentDetails?
           <Box className="details-area">
               <Box className="clinic-list-spacific">
                                      <span>Pet Name : {appointmentDetails?.pet_name}</span>
                                      <span>Pet Id :  {appointmentDetails?.pet_id}</span>
                                      <span>Client Name :  {appointmentDetails?.client_name}</span>
                                      <span>Created By :  {appointmentDetails?.created_by||"System"}</span>
                                      <Box sx={{margin:"auto"}} ><button onClick={handleClose} className="ok-button">Ok</button></Box>
                                      </Box>
                                      <p className="cross-details" onClick={handleClose}>X</p>
                                    
                                      </Box>
                                      :error?<p>No Record Found</p>: <Loader />
                                    }
            </FormView>
           
          </DivView>
      </Modal>
    </>
  );
}