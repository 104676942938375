import City from '../City'
import Service from '../service'
import { Box } from '@mui/system'
import React ,{useState} from 'react'
import Calendarcomponent from '../calendar'
import ClinicComponent from '../clinic'

export default function Header({handelClick,setDate,setService,error,setSelectedOption,selectedOption}) {
     
    const [city,setCity]=useState(1)  //fetch city and pass to clinic 
    const [clinicValue,setClinicValue]=useState(['7f5f4ef0-3a68-11ed-9f48-027b5103e28a'])
    const [flages,setFlages]=useState(false)

  return (
           <Box className="header-bookings">
                 <Box>
                  <img src="https://vetic-img.s3.ap-south-1.amazonaws.com/website/clinics/veticlogo.svg" alt="logo" />
                 </Box>
                 <Box className="header-bookings-filter">
                    <Box className="bookings-selection">
                        <Service setService={setService}/>
                    </Box>
                    <Box className="bookings-selection">
                        <Calendarcomponent setDate={setDate}/>
                    </Box>
                    <Box className="bookings-selection">
                    <City setCity={setCity} setFlages={setFlages}  setSelectedOption={setSelectedOption} setClinicValue={setClinicValue}/>
                    </Box>
                    <Box className="bookings-selection">
                    <ClinicComponent city={city}  flages={flages} setFlages={setFlages}  setSelectedOption={setSelectedOption} selectedOption={selectedOption} clinicValue={clinicValue} setClinicValue={setClinicValue}/>
                    </Box>
                    <Box className="submit-bb">
                    <button className='submit-slot' onClick={handelClick}>Submit</button>
                    </Box>
                </Box>
                <Box>
                {error&&<p style={{position:"absolute",marginTop:"0.2rem",color:"green"}}>Please select all the fields</p>}
                </Box>
            </Box>
  )
}
