import axios from 'axios';
import Header from './header'
import { Box } from '@mui/material'
import React,{useState,useEffect} from 'react'
import BookingsSlot from './bookingSlot'

const Index=()=>{

// set today
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  
    const [data,setData]=useState([])   
    const [date,setDate]=useState(formattedDate)
    const [service,setService]=useState('d10441ac-4549-11ed-af80-d1c5db9bbce2')  //consultation as default
    const [selectedOption, setSelectedOption] = useState(['7f5f4ef0-3a68-11ed-9f48-027b5103e28a']);
    const [error,setError]=useState(false)
    const [flags,setFlags]=useState(false)

    
//fetch slot api

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://org.vetic.in/clinic-org/bde-slots/?booking_date=${date}&service_id=${service}&clinic_ids=${selectedOption.filter(clinic => clinic !== '').join(',')}`);
        
        setData(response?.data?.data)
        setFlags(false)
      } catch (error) {
        console.error(error);
        setFlags(true)
      }
    };

    // first set default value

    useEffect(() => {
       fetchData()
    }, [])
    
    //click submit button 

    const handelClick=()=>{
      
        if(date&&service&&selectedOption){
            fetchData()
        }else{
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 4000);
        }   
      //   setTimeout(() => {
      //     setFlags(false)
      // }, 2000);    
    }

  return (
       <Box className="container">
         <Header handelClick={handelClick} setDate={setDate} setService={setService}  setError={setError} error={error}   setSelectedOption={setSelectedOption} selectedOption={selectedOption}/>
         {flags||data?.length===0?<p style={{textAlign:"center",marginTop:"5rem"}}>Please try again</p>: <BookingsSlot data={data} />}
       </Box>
   )
}


export default Index