import axios from 'axios';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React,{useState,useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function BasicSelect({setService}) {

  const [age, setAge] = React.useState('d10441ac-4549-11ed-af80-d1c5db9bbce2');
  const [mydata,setMydata]=useState([])


//call services api
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://org.vetic.in/clinic-org/bde-services/`);
         setMydata(response?.data?.data)
      
      } catch (error) {
        console.error(error);
      }
    };

    fetchData()

  }, []);


  const handleChange = (event) => {
    setAge(event.target.value);
    setService(event.target.value)
  };


  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Services</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={handleChange}
          
        >
        {mydata?.map((value,id)=>{

          return   <MenuItem value={value.id} key={id}>{value.name}</MenuItem>

        })}
        </Select>
      </FormControl>
    </Box>
  );
}
