import axios from 'axios';
import Box from '@mui/material/Box';
import React,{useState,useEffect} from 'react';
import {ListItemText, Checkbox, MenuItem, Select, DialogContent, FormControl} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';


export default function BasicSelect({city,setSelectedOption,clinicValue,setClinicValue,setFlages,flages}) {

  const [mydata,setMydata]=useState([])
  
  
//to fetch clinic list according to city
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://org.vetic.in/clinic-org/workspace/list?city_id=${city}`);
         setMydata(response?.data?.data?.results)
        
       
      } catch (error) {
        console.error(error);
      }
    };

    fetchData()

  }, [city]);

  const handleClinicChange = ({ target: { value } }) => {
    setFlages(false)
    setClinicValue( value === "string" ? value.split(",") : value);
    setSelectedOption(value)
    
}


const handelAll=()=>{
  const clinicIds = mydata?.map((clinic) => clinic.id);
  if(flages){
    setSelectedOption([])
    setFlages(false)
    
    setClinicValue([]);
  }else{
    setSelectedOption(clinicIds)
    setClinicValue(clinicIds);
    setFlages(true)
    
  }

}

// else if(flages){
//   return <span> {mydata.length} selected</span>
  return (
    <div>
      <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-multiple-checkbox-label">Clinics</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={clinicValue}
          onChange={handleClinicChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => 
            {if (selected.length === 0) {  
              return <em>Select Clinic</em>
            }
            return <span>{selected.length} selected</span> }}
          
        >
          {mydata.map((name) => (
            <MenuItem key={name.id} value={name.id}>
             {flages? <Checkbox  checked />: <Checkbox  checked={clinicValue.indexOf(name.id) > -1} />}
              <ListItemText primary={name.name} />
            </MenuItem>
          ))}
          <Box className="select-all-input">
           <input type="checkbox" checked={flages} className='input-select' id="vehicle1" name="vehicle1" value="Bike" onChange={handelAll}/> 
           <label for="vehicle1"  style={{cursor:"pointer",width:"100%"}}> Select All</label>
           </Box>
        </Select>
      </FormControl>
    </div>
  );
}
