import { Box } from '@mui/system'
import BookingsDetails from '../bookingDetails'

const  BookingSlot=({data})=> {
  
  
  return (
           <Box className="bookings-slot">
              <Box className="slot">
                  {data?.map((value,id)=>{

                       return   <Box className="slot-area" key={id}>
                                          <Box className="slot-area-clinic">
                                             <p style={{fontSize:"18px"}}>{value.clinic_name}</p>
                                           </Box>
                                         <Box className="slot-area-doctor">
                                            {value.data.map((val,id)=>{
                                                return <Box>
                                                        <Box className="doctor-slot" key={id}>
                                                          <p style={{width:"20%"}}>{val.staff_name}</p>
                                                           <Box className={`${val.data.length>12?'slot-area-booking':"slot-area-booking-not-sl"}` } >
                                                            {val.data.map((slot,id)=>{
                                                                 return <BookingsDetails text={slot.start_time} app_detail={slot.appointment_id} vet_Instance_id={slot.vetbuddy_instance_id}/>
                                                            })}
                                                         
                                                         </Box>
                                                         
                                                      </Box> 
                                                      {value.data.length-1===id?null:<p className='table-border-area'></p>}
                                                      </Box>
                                            })}
                                          </Box>
                                        
                                  </Box>  

                  })}
              </Box>
           
           </Box>
  )
}


export default BookingSlot